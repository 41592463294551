import { Injectable } from '@angular/core';

import { InboundEmailRule } from 'src/models';

import { GenericObjectsService } from './generic-objects.service';

@Injectable({
  providedIn: 'root',
})
export class InboundEmailrulesService extends GenericObjectsService<InboundEmailRule> {
  protected baseUrl = '/inbound-email-rules';
}
